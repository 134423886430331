#nav-icon{
  // border: 1px solid red;
  width: 27px;
  height: 33px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  // border-radius: 999px;
  display: block;
  position: absolute;
  height: rem-calc(2);
  width: 100%;
  background: $grijs;

  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2) {
  top: 8px;
}

#nav-icon span:nth-child(3) {
  top: 16px;
}

#nav-icon.open span:nth-child(1) {
  top: 8px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -40px;
}

#nav-icon.open span:nth-child(3) {
  top: 8px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}


header{
  background: white;
  width: 100%;
  height: 70px;
  box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.2);
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__logo{
      opacity: 0;
      @include breakpoint (small){
        padding: rem-calc(8 0 0 10);
        img{
          max-width: rem-calc(100);
        }
      }
      @include breakpoint (medium){
        padding-top: 13px;
        img{
          max-width: rem-calc(120);
        }
      }
    }
    &__menu{
      opacity: 0;
      @include breakpoint (small){
        padding: rem-calc(28 20 0 0);
      }
      @include breakpoint (medium){
        padding: rem-calc(28 15 0 0);
      }
    }
  }
}

.is-stuck{
  .header__logo{
    opacity: 1;
    transition: opacity 1s;
  }
  .header__menu{
    opacity: 1;
    transition: opacity 1s;
  }
}
